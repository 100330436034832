import { template as template_c8bf8687e9b54fbe9d60062a571eb1ea } from "@ember/template-compiler";
const FKText = template_c8bf8687e9b54fbe9d60062a571eb1ea(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
